<template>
  <div
    v-if="data.length>0"
    class="g-chart-container"
  >
    <h4 class="g-chart-container__title">
      Выполнение плана по Аукционам
    </h4>
    <GChart
      type="ColumnChart"
      :data="chartData"
      :options="options"
      :settings="settings"
    />
  </div>
</template>

<script>
import {GChart} from 'vue-google-charts';
import moment from 'moment';

export default {
  name: 'PlanAuctionsChart',
  components: {
    GChart,
  },
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
    period: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      settings: {language: 'ru', packages: ['corechart', 'line']},
      options: {
        annotations: {
          style: 'line',
          textStyle: {
            fontSize: 14,
            color: 'black',
          },
        },
        explorer: {
          axis: 'horizontal',
          keepInBounds: true,
          maxZoomIn: 0.1,
          maxZoomOut: 1,
        },
        chartArea: {
          top: '5%',
          left: '5%',
          height: '70%',
          width: '90%',
        },
        height: 800,
        width: '100%',
        titlePosition: 'none',
        textStyle: {color: 'black', fontSize: 12},
        colors: ['blue', 'red', 'green'],
        legend: {
          position: 'bottom',
          maxLines: 2,
          textStyle: {color: 'black', fontSize: 12},
        },
        vAxis: {
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          gridlines: {
            count: 0,
          },
          allowContainerBoundaryTextCutoff: true,
          minTextSpacing: 20,
          maxTextLines: 2,
          slantedText: true,
          showTextEvery: 1,
          textStyle: {
            color: 'black',
            fontSize: 14,
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      const result = [
        ['',
          'Фактическая экономия',
          {role: 'annotation', sourceColumn: 1},
          'Фактический перерасход',
          {role: 'annotation', sourceColumn: 2},
          'Плановоя экономия/перерасход',
          {role: 'annotation', sourceColumn: 3},
        ],
      ];
      this.data.forEach((item, key) => {
        const profit = item.startingPriceSum - item.endPriceSum;
        result.push([
          `${item.name} ${this.formatOnPeriod(item.date)}`,
          profit > 0 ? profit : 0,
          profit > 0 ? profit : null,
          profit < 0 ? profit : 0,
          profit < 0 ? profit : null,
          item.plannedProfit,
          item.plannedProfit ? item.plannedProfit : null,
        ]);
      });
      return result;
    },
  },
  methods: {
    formatOnPeriod(date) {
      let format = '';
      if (this.period ==='month') {
        format = 'MM-YYYY';
      } else if (this.period ==='week') {
        format = 'w Неделя YY г.';
      } else {
        format = 'DD.MM.YYYY';
      }
      return moment(date).format(format);
    },
  },
};
</script>

<style scoped  lang="scss">
.g-chart-container {
  position: relative;

  &__title {
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 10%;
    width: 80%;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  &__form-group {
    position: absolute;
    z-index: 1;
    top: 68px;
    left: 10%;
    width: 80%;
  }
}
</style>
